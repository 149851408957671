@import url('https://fonts.googleapis.com/css?family=Nunito:400,700');

body {
    font-family: 'Nunito', sans-serif;
    margin: 0;
}

p {
    margin: 0;
}

h2 {
    margin-bottom: 0;
}
